import './library/01_modernizr.min.js'
import Headroom from './library/02_headroom.min.js'
import './library/03_lazysizes.min.js'
import './library/04_magnific-popup.min.js'
import './library/05_cssua.min.js'
import './library/06_slick.js'
import './library/ortto.js'


const Mangrove = {

  /**
   * Initialize object
   * @function init
   * @memberof Mangrove
   */

  init() {
    this.cache();
    this.events();
    this.initHeadroom();
    this.initPopups();
    this.animateBanner();
    //this.preQualAlerts();
    this.pageAccessibilityFunctionalities();
    this.initSlick();
    this.initProcessNav();
    this.initVehiclesTabs();
    this.initFormAccordion();
    this.initAccordions();
    this.initFaqSmoothScroll();
  }, // init()

  /**
   * Cache reusable data
   * @function cache
   * @memberof Mangrove
   */

  cache() {

    this.$doc = $(document);
    this.$win = $(window);
    this.$html = $('html');
    this.$body = $('body');
    this.$mainWrap = $('main');
    this.$header = $('.js-header');
    this.$mobileNav = $('.js-mobile-nav');
    this.$hamburger = $('.js-hamburger');
    this.$mainMenuExpand = $('.footer nav > ul > li > a');
    this.$mainMenuLinks = $('.footer nav a');

  }, // _cache()

  /**
   * Attach event listeners
   * @function _events
   * @memberof Mangrove
   */

  events() {

    this.$doc.on(
        'click',
        '.js-hamburger',
        event => this.showMobileNav(event)
    );

    this.$doc.on(
        'click',
        '.js-share',
        event => this.openShareWindow(event)
    );

    this.$doc.on(
        'click',
        '.js-close-banner',
        event => this.closeBannerCTA(event)
    );

    if($(window).width() < 960) {
      this.$doc.on(
          'click',
          '.js-mobile-nav .nav--mobile__top > ul > li.menu-item-has-children > a',
          event => this.showMobileSubNav(event)
      );
    }

    if($(window).width() < 960) {
      this.$doc.on(
          'click',
          '.js-mobile-nav.nav--footer > ul > li.menu-item-has-children > a',
          event => this.showMobileSubNav(event)
      );
    }

    this.$doc.on(
        'focus',
        'nav > ul > li a',
        event => this.showSubNavOnFocus(event)
    );

    this.$win.on(
      'scroll',
      event => this.showBackToTop(event)
    );

    this.$doc.on(
      'click',
      '.js-scrollup',
      event => this.backToTop(event)
    );
    this.$doc.on(
      'click',
      '.js-story-popup',
      event => this.initStoryModal(event)
    );
    this.$doc.on(
      'click',
      '.js-story-close',
      event => this.closeStoryModal(event)
    );
    this.$doc.on(
      'click',
      '.js-dashboard-switch',
      event => this.dashboardSwitcher(event)
    );
    /* this.$doc.on(
      'click',
      '.js-disclosure',
      event => this.showButton(event)
    ); */


  }, // _events()

  openShareWindow(event) {
    let link = $(event.currentTarget).attr('href');
    if (link.substr(0, 6) != 'mailto') {
        event.preventDefault();
        window.open(link, '', 'width=600, height=400');
    }
  },

  initPopups() {
    $('.js-popup').magnificPopup({
      type: 'inline',
      midClick: true,
      removalDelay: 100,
      mainClass: 'popup--animate',
      focus: '.mfp-close',
      closeBtnInside:true
    });
    $('.js-video-popup').magnificPopup({
      type: 'inline',
      midClick: true,
      removalDelay: 100,
      mainClass: 'popup--animate',
      focus:".mfp-close",
      closeBtnInside:true,
      autoFocusLast: true,
      callbacks: {
      open: function() {
        $(this.content).find('video')[0].play();
        },
      close: function() {
        $(this.content).find('video')[0].load();
        }
      }
    });
    $('.js-youtube-popup').magnificPopup({
      type: 'iframe',
      midClick: true,
      removalDelay: 100,
      mainClass: 'popup--animate',
      preloader: true,
      focus:".mfp-close",
      closeBtnInside:true,
      autoFocusLast: true,
      iframe: {
        markup: '<div class="mfp-iframe-scaler" role="dialog" aria-label="Video about this step">'+
                  '<div class="mfp-close"></div>'+
                  '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>'+
                '</div>'
      }
    });
  },

  initHeadroom() {
    let theHeader = document.querySelector('.js-header');
    let headroom = new Headroom(theHeader);
    headroom.init();
  },

  animateBanner() {
    if ( $( ".hero--banner" ).length ) {
      $('.hero--banner').addClass('animate-down');
    }
  },

  initSlick() {
    function desktopSlick() {
      if($(window).innerWidth() > 700 ) {
        $(".vehicle-carousel--wrap").addClass('js-carousel');
        $('.js-carousel').not('.slick-initialized').slick({
          dots: false,
          infinite: true,
          speed: 300,
          slidesToShow: 4,
          slidesToScroll: 1,
          responsive: [
            {
              breakpoint: 9999,
              settings: {
                dots: false,
                infinite: true,
                speed: 300,
                slidesToShow: 4,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 700,
              settings: "unslick"
            }
          ]
        });
      } else {
        $(".vehicle-carousel--wrap").removeClass('js-carousel');
      }
    }
    desktopSlick();
    $(window).bind('resize',function(){
      desktopSlick();
    });

    $('.client-stories--carousel').slick({
      dots: false,
      infinite: true,
      speed: 300,
      slidesToShow: 2,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 960,
          settings: {
            dots: false,
            infinite: true,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    });
  },
  initProcessNav() {
    if ( $( ".js-process-nav" ).length ) {
      var top = $('.js-process-nav').offset().top;
      $(window).scroll(function (event) {
        var y = $(window).scrollTop();
        var bottom = (top + $('.process-steps--v2').height()) - $('.footer').height() - 80;
        if (y >= top && y <= bottom) {
          $('.js-process-nav').removeClass('hit-bottom');
          $('.js-process-nav').addClass('sticky');
        } else if (y > bottom) {
          $('.js-process-nav').removeClass('sticky');
          $('.js-process-nav').addClass('hit-bottom');
        } else {
          $('.js-process-nav').removeClass('sticky');
        }
      });
      var lastId,
          topMenu = $(".js-process-nav"),
          topMenuHeight = topMenu.outerHeight()+15,
          menuItems = topMenu.find("a"),
          scrollItems = menuItems.map(function(){
            var item = $($(this).attr("href"));
            if (item.length) { return item; }
          });
      menuItems.click(function(e){
        var href = $(this).attr("href"),
            offsetTop = href === "#" ? 0 : $(href).offset().top-topMenuHeight-1;
        $('html, body').stop().animate({
          scrollTop: offsetTop
        }, 700);
        e.preventDefault();
      });

      $(window).scroll(function(){
        var fromTop = $(this).scrollTop()+topMenuHeight;

        var cur = scrollItems.map(function(){
          if ($(this).offset().top < fromTop)
            return this;
        });

        cur = cur[cur.length-1];
        var id = cur && cur.length ? cur[0].id : "";

        if (lastId !== id) {
          lastId = id;
          menuItems
            .parent().removeClass("active")
            .end().filter("[href='#"+id+"']").parent().addClass("active");
        }
      });
    }
  },

  initVehiclesTabs() {
    if ( $( ".vehicle-options" ).length ) {
      $("[role='tab']").click(function(e) {
        e.preventDefault();
        $(this).attr("aria-selected", "true");
        $(this).parent().siblings().children().attr("aria-selected", "false");
        var tabpanelShow = $(this).attr("href");
        $(tabpanelShow).attr("aria-hidden", "false");
        $(tabpanelShow).siblings().attr("aria-hidden", "true").attr("tabindex", "-1");
      });
      $(window).on('load resize', function () {
        if ($(window).width() > 700) {
          $(".vehicle-options__main .grid__column").removeAttr("role").attr("aria-hidden", "false").attr("tabindex", "0");
        } else {
          $(".vehicle-options__main .grid__column").attr("role","tabpanel").attr("aria-hidden","true").attr("tabindex", "-1");
          $(".vehicle-options__main .grid__column").first().attr("aria-hidden","false").attr("tabindex", "0");
        }
      });
    }
  },

  initFormAccordion() {
    if ( $( ".recommendation-calculator__form" ).length ) {
      if ($(window).width() < 700) {
        $(".recommendation-calculator__question").click(function(e) {
          var el = $(this);
          e.preventDefault();
          el.parent().attr('aria-expanded', el.parent().attr('aria-expanded') === 'true' ? 'false' : 'true');
          el.siblings('.recommendation-calculator__answers').slideToggle();
        });
      }
      $(window).on('load resize', function () {
        if ($(window).width() > 700) {
          $(".recommendation-calculator__form fieldset").removeAttr("aria-expanded");
        } else {
          $(".recommendation-calculator__answers").hide();
          $(".recommendation-calculator__form fieldset").attr("aria-expanded","false");
        }
      });
    }
  },

  initAccordions() {
    $(document).ready(function() {
      $(".js-accordion-menu [id]").each(function() {
        var el = $(this);
        el.children().css('margin-top', -el.height());
      });
    });
    $(".js-accordion-menu [aria-expanded]").click(function(e) {
      e.preventDefault();
      var a = $(this).attr('href');
      var elExpanded = $(this).siblings(a).children();
      $(this).siblings(a).css('position', 'relative');
      $(this).attr('aria-expanded', $(this).attr('aria-expanded') === 'true' ? 'false' : 'true');
      if ($(this).attr('aria-expanded') === 'true') {
        elExpanded.css('margin-top', 0);
      } else {
        elExpanded.css('margin-top', -20 - elExpanded.innerHeight());
      }
    });
    function on_resize(c,t){onresize=function(){clearTimeout(t);t=setTimeout(c,20)};return c};
    on_resize(function() {
      $(".js-accordion-menu [aria-expanded=false] + [id]").css('position', 'absolute');
    });
  },

  initFaqSmoothScroll() {
    $(document).on('click', '.js-faq-nav a[href^="#"]', function (event) {
      event.preventDefault();
      $('html, body').animate({
          scrollTop: $($.attr(this, 'href')).offset().top
      }, 500);
    });
  },

  preQualAlerts() {
    if ( $( ".prequal-form" ).length ) {
      var $successMsg = "<p class='prequal-msg prequal-msg--success' role='alert'>You meet the key qualification criteria! Please continue to the application below to take the next step toward getting a clean vehicle grant.</p>";
      var $failureMsg = "<p class='prequal-msg prequal-msg--failure' role='alert'>Thanks for your interest! Based on the information you entered, you do not meet the qualification criteria at this time. Click here learn more about <a href='/eligibility'>program eligibility</a>.</p>";
      var $largeHouseholdMsg = "<p class='prequal-msg prequal-msg--lg-household' role='alert'>To calculate your household size, please only count yourself, your spouse and your dependent(s). For households with more than 8 people, you can calculate your qualifying income by starting with $169,520 and adding $5,400 for each additional person above 8.</p>";
      $(".prequal-form select").change(function(){
        if( ($("#ca-resident").val() === 'no') || ( $("#household-size").val() === '1' && $("#annual-income").val() > 48561 ) || ( $("#household-size").val() === '2' && $("#annual-income").val() > 65841 ) || ( $("#household-size").val() === '3' && $("#annual-income").val() > 83121 ) || ( $("#household-size").val() === '4' && $("#annual-income").val() > 100401 ) || ( $("#household-size").val() === '5' && $("#annual-income").val() > 117681 ) || ( $("#household-size").val() === '6' && $("#annual-income").val() > 134961 ) || ( $("#household-size").val() === '7' && $("#annual-income").val() > 152241 ) || ( $("#household-size").val() === '8' && $("#annual-income").val() > 169521 ) ) {
          $( ".prequal-msg" ).remove();
          $( ".prequal-form .wrapper" ).append( $failureMsg );
        }
        else if($("#household-size").val() === '9+') {
          $( ".prequal-msg" ).remove();
          $( ".prequal-form .wrapper" ).append( $largeHouseholdMsg );
        }
        else if( ( $("#ca-resident").val() === 'yes' && $("#household-size").val() === '1' && $("#annual-income").val() < 48561 && $("#annual-income").val() > 0 ) || ( $("#ca-resident").val() === 'yes' && $("#household-size").val() === '2' && $("#annual-income").val() < 65841 && $("#annual-income").val() > 0 ) || ( $("#ca-resident").val() === 'yes' && $("#household-size").val() === '3' && $("#annual-income").val() < 83121 && $("#annual-income").val() > 0 ) || ( $("#ca-resident").val() === 'yes' && $("#household-size").val() === '4' && $("#annual-income").val() < 100401 && $("#annual-income").val() > 0 ) || ( $("#ca-resident").val() === 'yes' && $("#household-size").val() === '5' && $("#annual-income").val() < 117681 && $("#annual-income").val() > 0 ) || ( $("#ca-resident").val() === 'yes' && $("#household-size").val() === '6' && $("#annual-income").val() < 134961 && $("#annual-income").val() > 0 ) || ( $("#ca-resident").val() === 'yes' && $("#household-size").val() === '7' && $("#annual-income").val() < 152241 && $("#annual-income").val() > 0 ) || ( $("#ca-resident").val() === 'yes' && $("#household-size").val() === '8' && $("#annual-income").val() < 169521 && $("#annual-income").val() > 0 ) ) {
          $( ".prequal-msg" ).remove();
          $( ".prequal-form .wrapper" ).append( $successMsg );
        }
        else {
          $( ".prequal-msg" ).remove();
        }
      });
    }
  },

  showMobileNav(event) {
    event.preventDefault();
    this.$mobileNav.toggleClass('is-active');
    this.$hamburger.toggleClass('is-active');
    if ( this.$mobileNav.hasClass('is-active') ) {
      this.$hamburger.attr("aria-pressed", "true");
      this.$hamburger.attr("aria-expanded","true");
    } else {
      this.$hamburger.removeAttr("aria-pressed");
      this.$hamburger.attr("aria-expanded","false");
    }
  },

  //Function for adding different Accessibility features troughout the website
  //Anything for adding accessibility attributes via js add here
  pageAccessibilityFunctionalities() {
    this.$mainMenuExpand.parent().each(function(){
      if($(this).hasClass('menu-item-has-children')){
        $(this).attr("aria-expanded","false");
      }
    });
    //this.$mainMenuLinks.attr('role','button');
    if ( $( ".post-navigation" ).length ) {
      $('.post-navigation').attr("aria-label","Post navigation").removeAttr("role");
    }
    var menuItems = document.querySelectorAll('li.has-submenu');
    Array.prototype.forEach.call(menuItems, function(el, i){
      el.querySelector('a').addEventListener("click",  function(event){
        if (this.parentNode.className == "has-submenu") {
          this.parentNode.className = "has-submenu open";
          this.setAttribute('aria-expanded', "true");
        } else {
          this.parentNode.className = "has-submenu";
          this.setAttribute('aria-expanded', "false");
        }
        event.preventDefault();
        return false;
      });
    });
  },

  //Keybord/focus functionality for main nav
  showSubNavOnFocus(event) {
    event.preventDefault();
    var el = $(event.currentTarget),
      elParents = el.parents(),
      selectedParent = '',
      self = this;

    elParents.each(function(){
      if($(this).hasClass('menu-item-has-children')) {
        selectedParent = $(this);
      }
    });
/*     el.focus(function(){
      if(el.parent().hasClass('menu-item-has-children')) {
        self.$mainMenuExpand.parent().each(function() {
          if($(this).hasClass('menu-item-has-children')) {
            $(this).attr("aria-expanded","false").removeAttr("aria-pressed").removeClass('focus');
          }
        });
        el.parent().addClass('focus').attr("aria-expanded", "true").attr("aria-pressed","true");
      } else if(selectedParent != 0) {
        selectedParent.addClass('focus').attr("aria-expanded", "true").attr("aria-pressed","true");
      } else if(!(el.parent().hasClass('menu-item-has-children'))){
        self.$mainMenuExpand.parent().removeAttr("aria-expanded").removeAttr("aria-pressed").removeClass('focus');
      }
    }); */
    $(".nav--main a[href='#']").click( function(event) {
      event.preventDefault();
    });
  },

  //Mobile nav functionality with accessability features added
  showMobileSubNav(event) {
    event.preventDefault();
    var el = $(event.currentTarget);
    el.parent().toggleClass('is-active');
    if (el.parent().hasClass('is-active')) {
      el.parent().attr("aria-pressed", "true");
      el.parent().attr("aria-expanded", "true");
    } else {
      el.parent().removeAttr("aria-pressed");
      el.parent().attr("aria-expanded", "false");
    }
  },

  closeBannerCTA(event) {
    var el = $(event.currentTarget);
    el.closest('.hero--banner').addClass('closed').removeClass('animate-down');
  },

  showBackToTop(event) {
    if(this.$win.scrollTop() > 100){
      $('.js-scrollup').fadeIn();
    }else{
      $('.js-scrollup').fadeOut();
    }
  },

  backToTop(event) {
    $("html, body").animate({ scrollTop: 0 }, 700);
    return false;
  },

  initStoryModal(event) {
    const el = $(event.currentTarget);
    let section = el.attr('href');
    event.preventDefault();

    $(section).addClass('is-active');
    $(section).attr("aria-expanded", "true");
    $('.js-story-slider').removeClass('is-active');
  },

  closeStoryModal(event) {
    const el = $(event.currentTarget);
    let section = el.parent().parent();
    event.preventDefault();

    $(section).removeClass('is-active');
    $(section).attr("aria-expanded", "false");
    $('.js-story-slider').addClass('is-active');
  },

  dashboardSwitcher(event) {
    event.preventDefault();
    var el = $(event.currentTarget);
    var select = el.attr('href');
    // console.log(select);
    $('.js-dashboard-switch').removeClass('is-active').removeAttr("aria-pressed");
    $('.js-dashboard-content').removeClass('is-active').attr("aria-expanded", "false");
    el.addClass('is-active').attr("aria-pressed", "true");
    $(select).addClass('is-active').attr("aria-expanded", "true");
    // if (el.hasClass('is-active')) {
    //   el.parent().attr("aria-pressed", "true");
    //   el.parent().attr("aria-expanded", "true");
    // } else {
    //   el.parent().removeAttr("aria-pressed");
    //   el.parent().attr("aria-expanded", "false");
    // }
  },

  /* showButton(event) {
    const el = $(event.currentTarget);
    const button = el.parent().siblings('.disabled');
    $(button).removeClass('disabled');
  } */

}; // Mangrove

Mangrove.init();

// Ortto cleanvehiclegrants capture code
window.ap3c = window.ap3c || {};
var ap3c = window.ap3c;
ap3c.cmd = ap3c.cmd || [];
ap3c.cmd.push(function() {
    ap3c.init('Y_QgB3Z0C-Hnm8VbY2xlYW52ZWhpY2xlZ3JhbnRz', 'https://capture-api.autopilotapp.com/');
    ap3c.track({v: 0});
});
ap3c.activity = function(act) { ap3c.act = (ap3c.act || []); ap3c.act.push(act); };
var s, t; s = document.createElement('script'); s.type = 'text/javascript'; s.src = "https://cdn2l.ink/app.js";
t = document.getElementsByTagName('script')[0]; t.parentNode.insertBefore(s, t);